import React, { useState } from "react";
import EmployerNav from "./Components/EmployerNav";
import { Typography } from "@mui/material";
import {
  Alert,
  Button,
  CircularProgress,
  Input,
  MenuItem,
  Option,
  Radio,
  Select,
  Textarea,
} from "@mui/joy";
import { ArrowLeftSharp, CancelOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../Config/firebase";
import { addDoc, collection } from "firebase/firestore";
import { jobProfiles } from "../../Exports";

export default function NewInternship() {
  const navigate = useNavigate();

  const [title, setTitle] = useState();
  const [stipend, setStipend] = useState(0);
  const [openings, setOpenings] = useState(1);
  const [duration, setDuration] = useState(3);
  const [workHours, setWorkHours] = useState("Part time");
  const [workType, setWorkType] = useState("Work From Home");
  const [skills, setSkills] = useState([]);
  const [typeInSkill, setTypeInSkill] = useState();
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState();
  const [desc, setDesc] = useState();
  const [profile, setProfile] = useState("UX/UI Designer");
  const [location, setLocation] = useState(); // New state for location

  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(false);

  const handleDurationChange = (e, val) => {
    setDuration(val);
  };

  const handleWorkHourChange = (e, val) => {
    setWorkHours(val);
  };

  const handleWorkTypeChange = (e, val) => {
    setWorkType(val);
  };

  const handleForm = async (e) => {
    e.preventDefault();

    if (
      !title ||
      stipend < 0 ||
      !openings ||
      !duration ||
      !workHours ||
      !workType ||
      !desc ||
      !location // Ensure location is filled
    ) {
      document.getElementById("location_alert").scrollIntoView();
      setAlert("Fill out all the fields");

      return;
    } else if (!skills || skills?.length === 0) {
      document.getElementById("location_alert").scrollIntoView();
      setAlert("At least one skill is required");

      return;
    }

    setLoading(true);

    try {
      const adddoc = await addDoc(collection(db, "internships"), {
        employer: auth?.currentUser?.uid,
        title: title,
        stipend: stipend,
        openings: openings,
        duration: duration,
        workType: workType,
        workHours: workHours,
        description: desc,
        skills: skills,
        questions: questions,
        applicants: 0,
        profile: profile,
        location: location, // Save the location field
      });

      if (adddoc.id) {
        navigate("/employer/dashboard", { replace: true });
      }
    } catch (e) {
      console.log(e);
      document.getElementById("location_alert").scrollIntoView();
      setAlert("An error occurred :/");

      return;
    } finally {
      setLoading(false);
    }
  };

  useState(() => {
    setTimeout(() => {
      setAlert();
    }, 6000);
  }, [alert]);

  return (
    <>
      <Helmet>
        <title>AccessCareer | Add New Internship</title>
        <meta
          name="description"
          content="Take your first step towards your dream career with AccessCareer! We offer a curated selection of internships across diverse fields, exclusively for college students and recent graduates. No more sifting through irrelevant job boards - find your perfect internship match on AccessCareer."
        />
        <meta
          name="keywords"
          content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
        />
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>

      <EmployerNav />

      <div className="flex flex-col justify-center items-center w-screen p-5">
        <form
          onSubmit={handleForm}
          className="flex flex-col border-gray-300 border-1 shadow-sm rounded-md p-4 max-h-[78vh] overflow-y-auto w-[50vw]"
        >
          <span id="location_alert"></span>
          <Typography component={"h1"}>Add New Internship</Typography>
          {alert && alert?.length > 0 && <Alert>{alert}</Alert>}
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="">Title</label>
            <Input
              placeholder="Eg. Software Engineering Intern, Finance"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="profile">Profile</label>
            {/* Fix this */}
            <Select
              label="profile"
              defaultValue={"UX/UI Designer"}
              onChange={(e, val) => {
                setProfile(val);
              }}
            >
              {jobProfiles.map((profile, index) => (
                <Option key={index} value={profile}>
                  {profile}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="">Stipend</label>
            <Input
              type="number"
              placeholder="Eg. 10000"
              onChange={(e) => {
                let val = e.target.value;

                if (val < 0) {
                  setStipend(0);
                } else {
                  setStipend(val);
                }
              }}
              value={stipend}
            />
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="">No.of Openings</label>
            <Input
              type="number"
              placeholder="Eg. 10000"
              onChange={(e) => {
                setOpenings(e.target.value);
              }}
              value={openings}
            />
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="">Duration</label>
            <Select value={duration} onChange={handleDurationChange}>
              <Option value={1}>1 Months</Option>
              <Option value={2}>2 Months</Option>
              <Option value={3}>3 Months</Option>
              <Option value={6}>6 Months</Option>
              <Option value={8}>8 Months</Option>
              <Option value={12}>12 Months</Option>
            </Select>
          </div>
          <div className="flex gap-2 w-full mt-2">
            <Select
              value={workHours}
              className="w-full"
              onChange={handleWorkHourChange}
            >
              <Option value={"Part time"}>Part time</Option>
              <Option value={"Full time"}>Full time</Option>
            </Select>
            <Select
              value={workType}
              className="w-full"
              onChange={handleWorkTypeChange}
            >
              <Option value={"Work From Home"}>Work From Home</Option>
              <Option value={"Hybrid"}>Hybrid</Option>
              <Option value={"In-Office"}>In-Office</Option>
            </Select>
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="">Description of the Internship</label>
            <Textarea
              placeholder="A short description on what the internship is about, what the intern will be responsible for etc..."
              maxRows={4}
              minRows={2}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <label htmlFor="">Location</label>
            <Input
              placeholder="Enter Internship Location"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            />
          </div>
          <div className="mt-2 flex flex-col gap-1 p-2 border-1 border-gray-200 rounded-md">
            <label htmlFor="">Skills required</label>
            <Input
              placeholder="Seperate skills with a space to add"
              name="skill"
              autoComplete={"off"}
              onChange={(e) => {
                const inputValue = e.target.value;
                setTypeInSkill(inputValue);

                if (inputValue.includes(" ")) {
                  setSkills((prevSkills) => [
                    ...prevSkills,
                    inputValue.split(" ")[0],
                  ]);
                  setTypeInSkill("");
                }
              }}
              value={typeInSkill}
            />

            <div className="flex flex-wrap">
              {skills &&
                skills?.length > 0 &&
                skills.map((skill) => {
                  return (
                    <div
                      key={skill}
                      className="flex p-2 w-max rounded-full bg-blue-300 items-center justify-center m-2 text-white font-semibold gap-1"
                    >
                      {skill.slice(0, 1).toUpperCase() + skill.slice(1)}
                      <CancelOutlined
                        className="cursor-pointer text-red-800"
                        fontSize="1rem"
                        onClick={() => {
                          setSkills((prevSkills) =>
                            prevSkills.filter(
                              (prevSkill) => prevSkill !== skill
                            )
                          );
                        }}
                      />
                    </div>
                  );
                })}
              {skills && skills?.length > 0 && (
                <div className="w-full">
                  <p
                    className="text-sm text-red-800 cursor-pointer w-max float-right"
                    onClick={() => {
                      setSkills([]);
                    }}
                  >
                    Clear skill list
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 mb-2 flex flex-col gap-1 p-2 border-1 border-gray-200 rounded-md">
            <label htmlFor="">Custom questions</label>
            <Textarea
              placeholder="Write your question and click add"
              maxRows={4}
              minRows={2}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              endDecorator={
                <Button
                  onClick={(e) => {
                    e.preventDefault();

                    if (question && question?.length > 0) {
                      setQuestions((prev) => [...prev, question]);
                      setQuestion("");
                    }
                  }}
                >
                  Add
                </Button>
              }
              value={question}
            />
            {questions?.length > 0 &&
              questions.map((q, index) => {
                return (
                  <p className="text-sm" key={index}>
                    {index + 1}. {q}
                  </p>
                );
              })}
            {questions && questions?.length > 0 && (
              <div className="w-full">
                <p
                  className="text-sm text-red-800 cursor-pointer w-max float-right"
                  onClick={() => {
                    if (!loading) {
                      setQuestions([]);
                    }
                  }}
                >
                  Clear questions
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-evenly items-center">
            <p
              className="flex cursor-pointer gap-1 hover:bg-gray-100 p-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftSharp />
              Go Back
            </p>
            <Button type="submit" disabled={loading}>
              {loading ? <CircularProgress /> : "Add Internship"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
