import React from 'react';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';

export default function SocialsAuth() {
  return (
    <div className="flex justify-center items-center space-x-6 mt-4">
      {/* Twitter (X) link */}
      <a
        href="https://x.com/AccessCare30041?t=eIiordxcjz2i5oMtYquO9Q&s=09"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-700 transition duration-300 cursor-pointer"
        aria-label="Twitter"
      >
        <FaTwitter size={30} />
      </a>

      {/* LinkedIn link */}
      <a
        href="https://www.linkedin.com/company/accesscareer/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-700 hover:text-blue-900 transition duration-300 cursor-pointer"
        aria-label="LinkedIn"
      >
        <FaLinkedin size={30} />
      </a>
    </div>
  );
}
