import "./App.css";

import logo_color from "./logo/color.png";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { onAuthStateChanged } from "firebase/auth";

import Home from "./Pages/Home";
import Auth from "./Pages/Auth";
import Internships from "./Pages/Internships";
import Dashboard from "./Pages/Company/Dashboard";
import Applications from "./Pages/Company/Applications";
import NewUser from "./Pages/NewUser";
import { auth } from "./Config/firebase";
import Resume from "./Pages/Resume";
import Internship from "./Pages/Internship";
import NewInternship from "./Pages/Company/NewInternship";
import ProtectedRouteStu from "./Utils/ProtectedRouteStu";
import ProtectedRouteEmp from "./Utils/ProtectedRouteEmp";
import Saved from "./Pages/Saved";
import Query from "./Pages/Query";
import StuResume from "./Pages/Company/StuResume";
import MyApplications from "./Pages/MyApplications";
import Chat from "./Pages/Chat";
import Profile from "./Pages/Profile/Profile";
import ViewProfile from "./Pages/Company/ViewProfile";
import Explore from "./Pages/Company/Explore";
import Tnc from './legals/Tnc';
import PrivacyPolicy from './legals/PrivacyPolicy';
import AboutUs from "./legals/AboutUs";
import Legals from "./legals/Legals";

function App() {
	const [user, setUser] = useState();

	onAuthStateChanged(auth, (user) => {
		setUser(user);
	});

	// useEffect(() => {
	// 	sendMail("usai7261@gmail.com", "Test", "<h1>Test mail</h1>");
	// }, []);

	const Page404 = () => {
		return (
			<>
				<Helmet>
					<title>AccessCareer - 404 - Page Not Found</title>
				</Helmet>

				<div class="text-center flex flex-col items-center justify-center w-screen h-screen select-none">
					<h1 class="mb-4 text-6xl font-semibold text-p2">404</h1>
					<p class="mb-4 text-lg text-p3">
						Oops! Looks like you're lost.
					</p>
					<div class=" animate-none flex flex-col items-center">
						<img
							src={logo_color}
							className="max-w-[5rem] drop-shadow-md"
							alt="AccessCareer"
						/>
						<p className="text-sm font-semibold select-none">
							AccessCareer
						</p>
					</div>
					<p class="mt-4 textp3">
						Let's get you back{" "}
						<a
							href="/"
							class="text-blue-500"
						>
							home
						</a>
						.
					</p>
				</div>
			</>
		);
	};

	return (
		<BrowserRouter>
			<Routes>
				{/* Student routes */}
				<Route
					path="/auth"
					element={<Auth />}
				/>
				<Route
					path="/register/se/:type"
					element={<NewUser />}
				/>

				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/home"
					element={<Home />}
				/>
				<Route
					path="/internships"
					element={<Internships />}
				/>
				<Route
					path="/resume"
					element={
						<ProtectedRouteStu>
							<Resume />
						</ProtectedRouteStu>
					}
				/>
				<Route
					path="/saved"
					element={
						<ProtectedRouteStu>
							<Saved />
						</ProtectedRouteStu>
					}
				/>
				<Route
					path="/applications"
					element={
						<ProtectedRouteStu>
							<MyApplications />
						</ProtectedRouteStu>
					}
				/>
				<Route
					path="/view/:id"
					element={
						<ProtectedRouteStu>
							<Internship />
						</ProtectedRouteStu>
					}
				/>
				<Route
					path="/query"
					element={
						<ProtectedRouteStu>
							<Query />
						</ProtectedRouteStu>
					}
				/>
				<Route
					path="/c/:id"
					element={
						<ProtectedRouteStu>
							<Chat />
						</ProtectedRouteStu>
					}
				/>
				<Route
					path="/profile"
					element={
						<ProtectedRouteStu>
							<Profile />
						</ProtectedRouteStu>
					}
				/>

				{/* Employer routes */}
				<Route
					path="/employer/dashboard"
					element={
						<ProtectedRouteEmp>
							<Dashboard />
						</ProtectedRouteEmp>
					}
				/>
				<Route
					path="/employer/applications/:id"
					element={
						<ProtectedRouteEmp>
							<Applications />
						</ProtectedRouteEmp>
					}
				/>
				<Route
					path="/employer/internship/new"
					element={
						<ProtectedRouteEmp>
							<NewInternship />
						</ProtectedRouteEmp>
					}
				/>
				<Route path="/employer/internship/:id" />
				<Route
					path="/resume/:id"
					element={
						<StuResume />
					}
				/>
				<Route
					path="/employer/c/:id"
					element={
						<ProtectedRouteEmp>
							<Chat />
						</ProtectedRouteEmp>
					}
				/>
				<Route
					path="/profile/:id"
					element={
						<ProtectedRouteEmp>
							<ViewProfile />
						</ProtectedRouteEmp>
					}
				/>
				<Route
					path="/employer/explore"
					element={
						<ProtectedRouteEmp>
							<Explore />
						</ProtectedRouteEmp>
					}
				/>

				{/* PAGE NOT FOUND */}
				<Route
					path="*"
					element={<Page404 />}
				/>

				{/* Legals */}
				{/* <Route 
					path="/legals"
					element={<Legals />}
				/>
				<Route
					path="/tnc"
					element={<Tnc />}
				/>
				<Route
					path="/privacy"
					element={<PrivacyPolicy />}
				/>
				<Route 
					path="/about"
					element={<AboutUs />}
				/> */}

				{/* <Route path="/admin/addcompany" element={<NewCompany />} />
        <Route path="/admin/login" element={<AdminLogin />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
