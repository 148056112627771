import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import logo from "../logo/color.png";
import InternshipCard from "../Components/InternshipCard";
import FilterForm from "../Components/FilterForm";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../Config/firebase";
import { Alert, CircularProgress } from "@mui/joy";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
import { Helmet } from "react-helmet";

export default function Internships() {
    const location = useLocation();
    const [profile, setProfile] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const [wfh, setWfh] = useState(false);
    const [hybrid, setHybrid] = useState(false);
    const [inOffice, setInOffice] = useState(false);
    const [stipend, setStipend] = useState('');
    const [duration, setDuration] = useState('');
    const [info, setInfo] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState([]);
    const [user] = useAuthState(auth);
    const [alert, setAlert] = useState();
    const [loading, setLoading] = useState(false);
    const [openFilters, setOpenFilters] = useState(false); // Added state for filter visibility
    const [locationFilterState, setLocationFilterState] = useState(''); // Renamed from location

    const fetchData = async () => {
    try {
        setLoading(true);

        let baseQuery = collection(db, "internships");

        // Apply filters to the query
        if (profile) {
            baseQuery = query(baseQuery, where("profile", "==", profile));
        }
        if (locationFilter) {
            baseQuery = query(baseQuery, where("location", "==", locationFilter));
        }
        // Apply additional filters for wfh, hybrid, in-office, etc.

        const docs = await getDocs(baseQuery);

        const promises = docs.docs.map(async (doc) => {
            const employerQuery = query(
                collection(db, "employers"),
                where("company", "==", doc.data().employer)
            );

            const employerSnapshot = await getDocs(employerQuery);
            const employersData = employerSnapshot.docs.map((employer) => employer.data());

            return {
                internship: { ...doc.data(), id: doc.id },
                employers: employersData,
            };
        });

        const newData = await Promise.all(promises);

        setInfo(newData);
        setFilteredInfo(newData);
    } catch (e) {
        console.error("Error fetching data:", e);
        setAlert("Failed to fetch internships. Please try again later.");
    } finally {
        setLoading(false);
    }
};

    useEffect(() => {
    fetchData();
    }, [profile, locationFilter, wfh, hybrid, inOffice, stipend, duration]);

    useEffect(() => {
        if (info.length > 0) {
            const filteredData = filterData(info);
            setFilteredInfo(filteredData);
        }
    }, [info, profile, locationFilter, wfh, hybrid, inOffice, stipend, duration]);

    const filterData = (data) => {
        return data.filter((item) => {
            const internship = item.internship;

            if (profile && internship.profile !== profile) {
                return false;
            }

            if (locationFilterState && internship.location !== locationFilterState) { // locationFilterState
                return false;
            }

            if (wfh && internship.workType !== "Work From Home") {
                return false;
            }
            if (hybrid && internship.workType !== "Hybrid") {
                return false;
            }
            if (inOffice && internship.workType !== "In-Office") {
                return false;
            }

            if (stipend && internship.stipend < parseInt(stipend, 10)) {
                return false;
            }

            if (duration && internship.duration < parseInt(duration, 10)) {
                return false;
            }

            return true;
        });
    };

    const resetFilters = () => {
        setLocationFilterState("")
        setProfile("");
        setLocationFilter("");
        setWfh(false);
        setHybrid(false);
        setInOffice(false);
        setStipend("");
        setDuration("");
        fetchData();
    };

    useEffect(() => {
        if (alert) {
            const timeout = setTimeout(() => {
                setAlert();
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [alert]);

        useEffect(() => {
        if (location.state) {
            setProfile(location.state.profile || '');
            setLocationFilter(location.state.location || '');
            setWfh(location.state.wfh || false);
            setHybrid(location.state.hybrid || false);
            setInOffice(location.state.inOffice || false);
            setStipend(location.state.stipend || '');
            setDuration(location.state.duration || '');
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state && location.state.pf) {
            setProfile(location.state.pf); // Set the profile based on the passed state
            console.log("Profile set to: ", location.state.pf); // Debugging log
        }
    }, [location.state]); 

    return (
    <>
      <Nav />

      <Helmet>
        <title>Best internship around me | India | Access Career</title>
        <meta name="description" content="Search for Internships around you, search through profile, location and Work type" />
        <meta property="og:title" content="Best internship around me | India | Access Career" />
        <meta property="og:description" content="Search for Internships around you, search through profile, location and Work type" />
{ //       <meta property="og:url" content={`https://www.accesscareer.in/internships/${id}/${title.replace(/\s+/g, '-').toLowerCase()}`} />
}        <meta property="og:type" content="website" />
      </Helmet>

      <div className="w-screen h-screen flex justify-center bg-gray-50">
        <div className="w-screen md:w-9/12 h-[98vh] overflow-y-auto shadow-md bg-white border-2br-gray-200 bl-gray-200 p-4 rounded-md scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded-full">
          <div className="flex justify-between items-center sticky">
            <img
              src={logo}
              style={{
                objectFit: "contain",
                width: "3rem",
              }}
              alt=""
            />
            <p className="text-xl font-semibold text-p1">Internships For You</p>
            <div className="">
              <button
                className="flex lg:hidden items-center gap-1"
                onClick={() => {
                  setOpenFilters(!openFilters);
                }}
              >
                Filters{" "}
                <IoFilter
                  className={`text-p3 ${
                    openFilters && "rotate-180"
                  } transition-all`}
                />
              </button>
              <div
                className={`${
                  openFilters ? "absolute lg:hidden" : "hidden"
                } bg-gray-50 shadow-md p-3 rounded-md right-5 border-t-2 border-p3 transition-all max-w-[30rem]`}
              >
                <FilterForm
                  setProfile={setProfile}
                  setLocationFilter={setLocationFilterState} 
                  wfh={wfh}
                  setWfh={setWfh}
                  hybrid={hybrid}
                  setHybrid={setHybrid}
                  inOffice={inOffice}
                  setInOffice={setInOffice}
                  stipend={stipend}
                  setStipend={setStipend}
                  duration={duration}
                  setDuration={setDuration}
                  pf={profile}
                  fetchData={fetchData}
                />
                <button
                  onClick={resetFilters}
                  className="mt-2 p-2 bg-red-500 text-white rounded-md"
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
          <div className="w-full border-1 border-gray-300 mt-2 mb-2"></div>
          <div className="flex gap-4 mt-5">
            <div className="hidden lg:flex flex-col border-1 p-4 w-max h-max rounded-md bg-gray-50 sticky top-0 max-w-[18em]">
              <div className="w-full border-b-1 border-gray-200 pb-1 mb-1">
                <p className="text-lg font-semibold flex justify-between items-center gap-1 text-center w-full">
                  <IoFilter />
                  Filter
                </p>
              </div>
              <FilterForm
                setProfile={setProfile}
                setLocation={setLocationFilterState}
                wfh={wfh}
                setWfh={setWfh}
                hybrid={hybrid}
                setHybrid={setHybrid}
                inOffice={inOffice}
                setInOffice={setInOffice}
                stipend={stipend}
                setStipend={setStipend}
                duration={duration}
                setDuration={setDuration}
                pf={profile}
                fetchData={fetchData}
              />
              <button
                onClick={resetFilters}
                className="mt-2 p-2 bg-red-500 text-white rounded-md"
              >
                Reset Filters
              </button>
            </div>

            <div className="flex flex-col w-full gap-8 right-0">
              {alert && alert?.length > 0 && <Alert>{alert}</Alert>}
              {loading ? (
                <div className="flex w-full h-full items-center justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {filteredInfo &&
                    filteredInfo?.length > 0 &&
                    filteredInfo.map((i, index) => (
                      <React.Fragment key={index}>
                        {i.employers.map((employer, employerIndex) => (
                          <InternshipCard
                            key={employerIndex}
                            title={i.internship.title}
                            company={employer.established}
                            image={employer.logo}
                            type={i.internship.workType}
                            pay={i.internship.stipend}
                            duration={i.internship.duration}
                            desc={employer.description}
                            id={i.internship.id}
                            fromSaved={false}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
