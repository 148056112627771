import React, { useState } from "react";
import { CgMore } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";

import logo from "../logo/color_withname.png";
import useUser from "../Utils/useUser";
import { Button, Input, Textarea } from "@mui/joy";
import { FormLabel } from "@mui/material";

export default function Query() {
  const [user, authChecked] = useUser();
  const navigate = useNavigate();
  const filereader = new FileReader();

  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [file, setFile] = useState();
  const [fileData, setFileData] = useState();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    filereader.readAsDataURL(event.target.files[0]);
    filereader.onload = () => {
      setFileData(filereader.result);
    };
  };

  const handleSubmit = () => {
    console.log("Submitted query:", title, desc, fileData);
  };

  return (
    <div>
      <div className="sticky flex flex-wrap gap-5 md:gap-0 md:flex-nowrap p-5 pr-10 pl-10 w-screen justify-between items-center shadow-md bg-white">
        <div className="flex justify-between sm:w-full md:w-auto items-center gap-2">
          <Link to="/" className="cursor-pointer">
            <img
              src={logo}
              style={{ objectFit: "contain", width: "12rem" }}
              alt=""
            />
          </Link>
        </div>

        <ul
          className={`flex flex-wrap gap-8 items-center justify-center list-none`}
        >
          <li>
            <Link className={`flex cursor-pointer items-center text-p1`} to="/">
              Home
            </Link>
          </li>
        </ul>
      </div>

      <div className="w-screen flex justify-center items-center">
        <form
          className="m-10 p-5 border-1p5 border-gray-300 w-screen md:w-[70vw]"
          onSubmit={handleSubmit}
        >
          <div className="m-3">
            <Input
              placeholder="Title of your query..."
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
            />
          </div>
          <div className="m-3">
            <Textarea
              placeholder="Describe your query..."
              minRows={3}
              maxRows={5}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              required
            />
            <div className="mt-3">
              <input type="file" onChange={handleFileChange} accept="image/*" />
            </div>
            <div className="flex gap-2 w-full justify-between items-center pt-3">
              <Button
                className="w-full"
                variant="outlined"
                color="danger"
                onClick={() => {
                  navigate("/");
                }}
              >
                Cancel
              </Button>
              <Button type="submit" className="w-full">
                Submit
              </Button>
            </div>
          </div>

          <div className="p-3">
            <FormLabel>Points to remember</FormLabel>

            <ul
              style={{
                listStyle: "disc",
              }}
              className="m-3 text-sm"
            >
              <li>
                <b>State your purpose:</b> Briefly explain why you're contacting
                them and what specific information you need.
              </li>
              <li>
                <b>Be professional:</b> Maintain a respectful and courteous
                tone.
              </li>
              <li>
                <b>Ask specific questions:</b> Tailor your questions to your
                needs and interests.
              </li>
              <li>
                <b>Provide context (optional):</b> Briefly explain relevant
                background information (optional).
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
}
