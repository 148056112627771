
import React, { useEffect, useState } from "react";
import Nav from "../Components/Nav";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftSharp, Refresh } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../Config/firebase";
import useUser from "../Utils/useUser";
import { CgSpinner } from "react-icons/cg";
import Chats from "../Components/Chats";

export default function MyApplications() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user, authChecked] = useUser();

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("all");

const fetchData = async () => {
    if (!user) return;

    setLoading(true);
    setData([]); // Clear previous data

    try {
        let ref_applications = collection(db, "applications");
        if (filter !== "all") {
            ref_applications = query(
                ref_applications,
                where("student", "==", user?.uid),
                where("status", "==", filter.charAt(0).toUpperCase() + filter.slice(1))
            );
        } else {
            ref_applications = query(ref_applications, where("student", "==", user?.uid));
        }

        const applicationsSnapshot = await getDocs(ref_applications);
        const applicationsData = await Promise.all(
            applicationsSnapshot.docs.map(async (application) => {
                const applicationDoc = application.data();

                if (!applicationDoc.internship) {
                    console.warn(`Application does not have an associated internship ID: ${application.id}`);
                    return null; // Skip this application if internship ID is missing
                }

                const internshipRef = doc(db, "internships", applicationDoc.internship);
                const internshipDoc = await getDoc(internshipRef);

                if (!internshipDoc.exists()) {
                    console.warn(`Internship document does not exist: ${applicationDoc.internship}`);
                    return null; // Skip this iteration if internship doesn't exist
                }

                const internshipData = internshipDoc.data();
                const employerRef = doc(db, "employers", internshipData.employer);
                const employerDoc = await getDoc(employerRef);

                if (!employerDoc.exists()) {
                    console.warn(`Employer document does not exist for internship: ${internshipRef.id}`);
                }

                return {
                    applications: applicationDoc,
                    internship: {
                        data: internshipData,
                        id: internshipDoc.id,
                    },
                    employer: employerDoc.exists() ? employerDoc.data() : {},
                };
            })
        );

        setData(applicationsData.filter(item => item !== null)); // Filter out null values
    } catch (error) {
        console.error("Error fetching applications:", error);
    } finally {
        setLoading(false);
    }
};


  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [filter, user]);

  return (
    <div>
      <Nav />

      <Helmet>
        <title>
          AccessCareer | My Applications | Your anchor to a bright future
        </title>
        <meta
          name="description"
          content="Take your first step towards your dream career with AccessCareer! We offer a curated selection of internships across diverse fields, exclusively for college students and recent graduates. No more sifting through irrelevant job boards - find your perfect internship match on AccessCareer."
        />
        <meta
          name="keywords"
          content="Software engineering, Mechanical engineering, Electrical engineering, Chemical engineering, Civil engineering, Biomedical engineering, Data science, Cybersecurity, Artificial intelligence, Machine learning, Cloud computing, Software development, Finance, Marketing, Management, Human resources, Operations, Consulting, Graphic design, Product design, UX/UI design, Web design, Fashion design, Interior design, Internship, College internships, Recent graduate internships, Entry-level jobs, Job search, Career development"
        />
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>

      <div className="flex h-[100%] items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-5 p-8 bg-white h-[100%] w-screen md:w-[75vw] border-r-0 border-l-0 border-gray-200">
          <p className="text-lg font-semibold font-sans">
            My Applications
          </p>
          <div className="flex w-full justify-between items-center border-1 border-gray-300 rounded-md p-2">
            <button
              className="flex items-center w-max"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftSharp />
              Go Back
            </button>
            {!loading && (
              <select
                name=""
                id=""
                value={filter}
                className="border-1 border-gray-300 rounded-md p-2 outline-none"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option
                  value=""
                  disabled
                  defaultValue
                >
                  Filter Status
                </option>
                <option value="shortlisted">Shortlisted</option>
                <option value="applied">Applied</option>
                <option value="hired">Hired</option>
                <option value="all">All</option>
              </select>
            )}
            <button
              className="flex items-center w-max"
              onClick={fetchData}
            >
              Refresh
              <Refresh />
            </button>
          </div>
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <CgSpinner color="blue" />
            </div>
          ) : (
            <table className="w-full m-5 p-2 border-1 border-gray-300 shadow-md">
              <thead>
                <tr className="border-1 border-gray-300">
                  <th className="p-2 text-p1">Internship</th>
                  <th className="p-2 text-p1">Location</th>
                  <th className="p-2 text-p1">Stipend</th>
                  <th className="p-2 text-p1">Applied</th>
                  <th className="p-2 text-p1">Status</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item) => {
                    const appliedDate = new Date(
                      item?.applications.dateApplied
                    ).toLocaleDateString();

                    return (
                      <tr
                        className="text-center border-b-1 border-gray-200"
                        key={item.internship.id}
                      >
                        <td className="p-4 text-blue-400">
                          <Link
                            to={`/view/${item?.internship.id}`}
                          >
                            {item?.internship.data.title}
                          </Link>
                        </td>
                        <td className="p-4">
                          {item?.internship?.data?.location}
                        </td>
                        <td className="p-4">
                          {item?.internship?.data?.stipend}
                        </td>
                        <td className="p-4">
                          {appliedDate}
                        </td>
                        <td className="p-4">
                          <div className="flex items-center justify-center gap-2">
                            <StatusDot
                              status={
                                item?.applications.status
                              }
                            />
                            {item?.applications.status}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <Chats />
    </div>
  );
}

const StatusDot = ({ status }) => {
  return (
    <div
      className={`w-2 h-2 rounded-full ${
        status.toLowerCase() === "shortlisted"
          ? "bg-green-700 shadow-green-700"
          : status.toLowerCase() === "hired"
          ? "bg-blue-500 shadow-blue-500"
          : status.toLowerCase() === "applied"
          ? "bg-yellow-500 shadow-yellow-500"
          : "bg-red-500 shadow-red-500"
      }`}
    ></div>
  );
};


