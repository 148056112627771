import React, { useState } from "react";
import logo from "../logo/color_withname.png";

import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Login, Logout, Menu } from "@mui/icons-material";
import { auth } from "../Config/firebase";
import useUser from "../Utils/useUser";

export default function Nav() {
	const location = useLocation();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(true);
	const [openMenu, setOpenMenu] = useState(false);

	const [user, authChecked] = useUser();

	const logout = () => {
		auth.signOut().then(() => {
			navigate("/auth");
		});
	};

	return (
		<>
			<div className="sticky flex flex-wrap gap-5 md:gap-0 md:flex-nowrap p-5 pr-10 pl-10 w-screen justify-between items-center shadow-md bg-white z-50">
				<div className="flex justify-between sm:w-full md:w-auto items-center gap-2">
					<Link
						to="/"
						className="cursor-pointer"
					>
						<img
							src={logo}
							style={{ objectFit: "contain", width: "12rem" }}
							alt=""
						/>
					</Link>
					{/* <CgMore
						className="visible md:hidden"
						onClick={handleMoreClick}
					/> */}
				</div>

				{/* {user && (
					<input
						type="text"
						className=" w-full md:w-5/12 bg-transparent border-1 border-p1 p-3 outline-none rounded-full"
						placeholder="Search AccessCareer..."
					/>
				)} */}

				<ul
					className={`flex flex-wrap gap-8 items-start justify-center list-none ${
						isOpen ? "flex" : "hidden"
					} md:flex`}
				>
					<li>
						<Link
							className={`flex flex-col cursor-pointer items-center text-p1`}
							to="/internships"
						>
							Internships
						</Link>
					</li>

					<li>
						<Menu
							className="cursor-pointer text-p1"
							onClick={() => {
								setOpenMenu(!openMenu);
							}}
						/>
						<div
							className={`md:top-16 md:right-12 p-2 rounded-md bg-white shadow-md border-1 border-gray-200 ${
								openMenu
									? "absolute transition-all duration-300 ease-in-out"
									: "hidden"
							}`}
						>
							<ul className="w-full space-y-1">
								<li className="flex justify-between items-center">
									<Link
										className="flex flex-col cursor-pointer items-center text-p1 w-full"
										title="Saved"
										to="/saved"
									>
										<span
											className={
												location.pathname !== "/saved"
													? "font-normal"
													: "font-semibold"
											}
										>
											Saved
										</span>
									</Link>
								</li>
								<li className="flex justify-between items-center">
									<Link
										className="flex flex-col cursor-pointer items-center text-p1 w-full"
										title="Resume"
										to="/resume"
									>
										<span
											className={
												location.pathname !== "/resume"
													? "font-normal"
													: "font-semibold"
											}
										>
											Resume
										</span>
									</Link>
								</li>
								<li className="flex justify-between items-center">
									<Link
										className="flex flex-col cursor-pointer items-center text-p1 w-full"
										title="Applications"
										to="/applications"
									>
										<span
											className={
												location.pathname !==
												"/applications"
													? "font-normal"
													: "font-semibold"
											}
										>
											Applications
										</span>
									</Link>
								</li>
								<li className="flex justify-between items-center">
									<Link
										className="flex flex-col cursor-pointer items-center text-p1 w-full"
										title="Applications"
										to="/profile"
									>
										<span
											className={
												location.pathname !==
												"/profile"
													? "font-normal"
													: "font-semibold"
											}
										>
											Profile
										</span>
									</Link>
								</li>
								<li className="p-1 border-1 border-gray-200 rounded-md mt-1">
									{user ? (
										<button
											className="flex justify-between w-full cursor-pointer items-center text-p1"
											title="Logout"
											onClick={logout}
										>
											<Logout />
											Logout
										</button>
									) : (
										<Link
											className="flex justify-between w-full cursor-pointer items-center text-p1"
											title="Login"
											to="/auth"
										>
											<Login />
											Login
										</Link>
									)}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
}
